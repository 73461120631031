<template>
    <div>
        <v-menu bottom offset-x rounded="lg" transition="slide-y-transition" color="primary">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" color="teal darken-1" class="white--text ma-1">
                    <v-icon>fas fa-ellipsis-v</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item @click.prevent="editEntry(item)" v-if="item.cantidad === item.existencia">
                    <v-list-item-title>
                        <v-icon small class="mr-2">fas fa-edit</v-icon> Editar entrada
                    </v-list-item-title>
                </v-list-item>
                <v-list-item link :to="{ name: 'ListaEntradaDetalleLote', params: { entradaDetalleId: item.id }}">
                    <v-list-item-title>
                        <v-icon small class="mr-2">fas fa-balance-scale</v-icon> Detalle
                        entrada
                    </v-list-item-title>
                </v-list-item>
                <v-list-item link @click.prevent="downloadExcelVentaDetalle(item.id, 1)">
                    <v-list-item-title>
                        <v-icon small class="mr-2">fas fa-chart-line</v-icon> Reporte de ventas
                    </v-list-item-title>
                </v-list-item>
                <v-list-item link @click.prevent="downloadExcelVentaDetalle(item.id, 2)">
                    <v-list-item-title>
                        <v-icon small class="mr-2">fas fa-exclamation-triangle</v-icon> Reporte de mermas
                    </v-list-item-title>
                </v-list-item>
                <v-list-item @click.prevent="showEvidences(item.entrada.id)">
                    <v-list-item-title>
                        <v-icon small class="mr-2">fas fa-images</v-icon> Imagenes o Evidencias
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <ViewFilesTable :lstFiles="lstEvidences" :dialog="dialogEvidences"
        title-files="Ficheros y evidencias de entrada"
        v-if="dialogEvidences" :handleDialogFiles="handleDialogFiles">
        </ViewFilesTable>
    </div>
</template>
<script>
import EntradaEvidenceFileService from '@/service/entrada/EntradaEvidenceFileService';
import VentaDetalleLoteDataService from '@/service/venta/VentaDetalleLoteDataService';
import Camera from '@/assets/camera.png'
import ViewFilesTable from '@/components/shared/ViewFilesTable.vue';

export default {
    name: 'ActionsEntrada',
    props: {
        item:{ type: Object, required: true },
        editEntry: { type: Function },
    },
    components: { ViewFilesTable },
    data() {
        return {
            dialogEvidences: false,
            lstEvidences: [],
        }
    },
    methods: {
        downloadExcelVentaDetalle(entradaDetalleId, tipoSalidaId){
            this.$swal.fire({
                title: 'Quieres continuar con la descarga del reporte de detalle de la entrada?',
                text: 'El tiempo de descarga puede variar dependiendo de la cantidad de registros',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    const params = { entradaDetalleId, tipoSalidaId }
                    try {
                        return await VentaDetalleLoteDataService.downloadVentaDetalleLoteExcelByTipoSalida(params);
                    } catch (error) {
                        this.$swal.showValidationMessage(`Petición fallida: ${error}`);
                    }
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    if(result.value.status == 204){
                        this.$swal.fire({ icon: 'info', title: 'Reporte', text: 'No hay registros para descargar', showConfirmButton: false, timer: 1500 });
                    } else {
                        const url = window.URL.createObjectURL(new Blob([result.value.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `Salidas_de_la_entrada_${this.item.ticketFolio}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.$swal.fire({ icon: 'success', title: 'Reporte', text: 'Descarga completada', showConfirmButton: false, timer: 1500 });
                    }
                }
            })
        },
        getListEvidences(entradaId) {
            let params = { page: 0, size: 25 };
            this.lstEvidences = [];
            EntradaEvidenceFileService.table( params, entradaId ).then( response => {
                let evidences = response.data.rows.map(evidence => {
                    return {
                        ...evidence,
                        typeFile: evidence.type.startsWith('image') ? 'image' : 'pdf',
                        url: evidence.name != null ? `${this.$store.state.auth.rutaFichero}entradaEvidences/${evidence.name}` : Camera
                    };
                });
                this.lstEvidences = evidences;
            }).catch(err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al cargar las evidencias' + err.response.data, showConfirmButton: false, timer: 3500 });
            })
        },
        showEvidences(entradaId){
            this.dialogEvidences = true
            this.getListEvidences(entradaId)
        },
        handleDialogFiles() { this.dialogEvidences = !this.dialogEvidences }
    }
}
</script>

<style scoped>
    .file-link {
    display: block;
    text-decoration: none;
    color: #1976d2;
    font-weight: bold;
    margin-top: 10px;
    }
    .file-link:hover {
    text-decoration: underline;
    }
</style>